import moment from "moment/moment";

export const timeFilter = (date: Date, timeRange : 'day' | 'week' | 'month' = 'day') => {
    const day = moment(date);
    const today = moment();

    return day.isSame(today, timeRange);
};

export const getSum = (numbersArray: number[]) => numbersArray.reduce((partialSum: number, a : number) => partialSum + a, 0);
export const getAvg = (numbersArray: number[]) => (getSum(numbersArray) / numbersArray.length) || 0;

export const getMetrics = (metricsData: any, timeRange: 'day' | 'week' | 'month' | 'all' = 'all') => {
    const timeSeries = timeRange === 'all' ? metricsData?.timeSeries :  metricsData?.timeSeries.filter((dataPoint: any) => timeFilter(new Date(dataPoint.createdAt), timeRange));
    const points : number[] = timeSeries.map((dataPoint: any) => dataPoint.points);
    const availablePointsList  : number[] = timeSeries.map((dataPoint: any) => dataPoint.availablePoints);
    const totalPoints = getSum(points);
    const availablePoints = getSum(availablePointsList);
    return {
        totalPoints: getSum(points),
        averagePoints: parseInt(new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 3 } ).format(getAvg(points))),
        availablePoints,
        averageAvailablePoints: getAvg(availablePointsList),
        minimumPoints: Math.min(...points),
        maximumAvailable: Math.max(...availablePointsList),
        maximumPoints: Math.max(...points),
        totalConversations: timeSeries.length,
        totalDentistConversations: (timeSeries.filter((dataPoint: any) => dataPoint.conversation.leadType === "DENTIST")).length,
        totalAssistantConversations: (timeSeries.filter((dataPoint: any) => dataPoint.conversation.leadType === "ASSISTANT")).length,
        successRate: new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 1 } ).format((totalPoints / availablePoints) * 100),
        timeSeries
    }
};