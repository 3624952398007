import {IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner, useIonRouter,} from "@ionic/react";
import {FC, useEffect, useState} from "react";

export const NewDentistConversationModal: FC<{ lead?: any, onClose?: any, conversation: any }> = ({lead, onClose, conversation}) => {

    const [started, setStarted] = useState<boolean>(false);

    const router = useIonRouter();

    useEffect(() => {
        if (started) {
            setTimeout(()  => {
                router.push('/akquisition-call/'+conversation.id, "back", 'push');
                onClose();
             }, 2000);
        }
    }, [started]);

    return (
        <IonPage>
            <IonContent fullscreen className="modaaal">
                <IonGrid className="main-grid">
                    <IonRow className="main-row ion-justify-content-center ion-align-items-center">
                        <div className="the-holder ion-padding">
                            <IonRow className="title-row">
                                <IonCol size="8" class="ion-text-left">
                                    <h6>Anfahrt</h6>
                                </IonCol>
                                <IonCol size="4" class="ion-text-right" onClick={() => {
                                    onClose()
                                }}>
                                    <p>Abbrechen</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-align-items-center ion-padding">
                                <img src="/assets/imgs/car.svg" alt=""/>
                            </IonRow>
                            {!started && <IonRow className={'ion-justify-content-center ion-text-center'}>
                                <IonButton onClick={() => { setStarted(true) }}>Zahnarztpraxis anfahren</IonButton>
                            </IonRow>}
                            {started && <IonRow className={'ion-justify-content-center ion-text-center'}>
                                <p>Zahnarzt wird angefahren</p>
                                <IonSpinner name="crescent"/>
                            </IonRow>}
                        </div>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

