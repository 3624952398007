import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {RETRIEVE_TIME_SERIES} from "../../../schemas/metrics";
import { getMetrics } from "../../../utils/metrics"

export const useMetrics = () => {
    const { refetch, data, loading } = useQuery(RETRIEVE_TIME_SERIES);
    const [metricsTotal, setMetricsTotal] = useState<any>(null);
    const [metricsToday, setMetricsToday] = useState<any>(null);
    const [metricsWeek, setMetricsWeek] = useState<any>(null);
    const [metricsMonth, setMetricsMonth] = useState<any>(null);

    useEffect(() => {
        if (data?.retrieveTimeSeries) {
            setMetricsTotal(getMetrics(data?.retrieveTimeSeries, 'all'));
            setMetricsToday(getMetrics(data?.retrieveTimeSeries, 'day'));
            setMetricsWeek(getMetrics(data?.retrieveTimeSeries, 'week'));
            setMetricsMonth(getMetrics(data?.retrieveTimeSeries, 'month'));
        }
    }, [data]);

    return { metricsTotal, metricsToday, metricsWeek, metricsMonth, loading };
};