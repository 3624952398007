import React, {createRef, FC, useContext, useEffect, useState} from "react";
import {
    IonBadge, IonFab,
    IonFabButton, IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    useIonRouter
} from "@ionic/react";
import {Redirect, Route} from "react-router";
import {AuthProvider, useAuth} from "../../providers/auth";
import {Dashboard} from "../../pages/Dashboard";
import {Ranking} from "../../pages/Ranking";
import {Profile} from "../../pages/Profile";
import {Conversations} from "../../pages/Conversations";
import {AkquisitionCall} from "../../pages/AquisitionCall";
import {add, playCircle} from "ionicons/icons";
import { Icon } from '@iconify/react';
import UIContext from "../../providers/UIProvider";

interface TabsProps {

};

export const Tabs: FC<TabsProps> = () => {

    const { setShowFab, roleMode } = useContext(UIContext);

    const router = useIonRouter();

    useEffect(() => {
        setShowFab(true);
    }, []);

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const tabChange = (path: string) => {
        router.push(path, "back", "push");
    };

    return <>

        <IonTabs>
            <IonRouterOutlet>
                <Redirect exact path="/app" to="/app/dashboard"/>
                <Route exact path="/app/dashboard">
                    <AuthProvider pageProps={{protected: true}}>
                        <Dashboard/>
                    </AuthProvider>
                </Route>
                <Route path="/app/ranking">
                    <AuthProvider pageProps={{protected: true}}>
                        <Ranking/>
                    </AuthProvider>
                </Route>
                <Route path="/app/conversations">
                    <AuthProvider pageProps={{protected: true}}>
                        <Conversations/>
                    </AuthProvider>
                </Route>
                <Route exact path="/app/aquisition-call/:dentistConversationId?">
                    <AuthProvider pageProps={{protected: true}}>
                        <AkquisitionCall/>
                    </AuthProvider>
                </Route>
                <Route exact path="/app/profile">
                    <AuthProvider pageProps={{protected: true}}>
                        <Profile/>
                    </AuthProvider>
                </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" className={"ion-no-border"}>
                <IonTabButton tab="tab1" onClick={() => tabChange('/app/dashboard')}>
                    <Icon icon="ci:dashboard" width={"24px"} height={"24px"} />
                    <IonLabel>Dashboard</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab2" onClick={() => tabChange('/app/conversations')} className={"comments"}>
                    <Icon icon="material-symbols:chat" width={"24px"} height={"24px"} />
                    <IonLabel>Gespräche</IonLabel>
                </IonTabButton>

                <IonTabButton tab="tab3">
                    <div></div>
                </IonTabButton>

                <IonTabButton tab="tab4" onClick={() => tabChange('/app/ranking')} className={"notifs"}>
                    <Icon icon="icon-park-solid:ranking-list" width={"24px"} height={"24px"} />
                    <IonLabel>Rangliste</IonLabel>
                </IonTabButton>

                <IonTabButton tab="tab5" onClick={() => tabChange('/app/profile')}>
                    <Icon icon="ic:baseline-person" width={"24px"} height={"24px"} />
                    <IonLabel>Konto</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    </>
};