import { gql } from "@apollo/client"
export const REGISTER_POINTS = gql`
    mutation registerPoints (
        $availablePoints: Int!,
        $minimumPoints: Int!,
        $points: Int!,
        $conversationId: String!
    ) {
        registerPoints(
            data: {
                availablePoints: $availablePoints,
                minimumPoints: $minimumPoints,
                points: $points,
                conversationId: $conversationId,
            }
        ) {
            id
            dentistConversationId
        }
    }
`;