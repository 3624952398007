import {gql} from "@apollo/client";

export const RETRIEVE_RANKING_TIME_SERIES = gql`
    query ranking {
        retrieveRankingTimeSeries {
            timeSeries {
                createdAt
                availablePoints
                minimumPoints
                points
                conversation {
                  leadType              
                }
                user {
                  id                     
                  firstname
                  lastname
                  laborname
                }               
            }
        }
    }
`;