import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {RETRIEVE_RANKING_TIME_SERIES} from "../../../schemas/ranking";
import { getMetrics } from "../../../utils/metrics"

const getUsersByIds = (timeSeries: any[]) => {
    return timeSeries.reduce((rv, x) => {
        if (!rv[x.user.id]) rv[x.user.id] = x.user;
        return rv;
    }, {});
};

const getTimeSeriesByUser = (timeSeries: any[]) => {
    // group by user
    const groupedByUser = timeSeries.reduce((rv, x) => {
        if (!rv[x.user.id]) rv[x.user.id] = [];
        rv[x.user.id].push(x);
        return rv;
    }, {});
    return groupedByUser;
};

const objectMap = (obj: any, fn: any) =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], i) => [k, fn(v, k, i)]
        )
);

const getRankAggreations = (timeSeriesByUser : any[], timeRange: 'day' | 'week' | 'month' | 'all' = 'all') => objectMap(timeSeriesByUser, ((userTimerSeries: any) => {
        return getMetrics({ timeSeries: userTimerSeries }, timeRange);
}));

export const useRanking = () => {
    const { refetch, data, loading } = useQuery(RETRIEVE_RANKING_TIME_SERIES);
    const [metricsTotal, setMetricsTotal] = useState<any>(null);
    const [metricsToday, setMetricsToday] = useState<any>(null);
    const [metricsWeek, setMetricsWeek] = useState<any>(null);
    const [metricsMonth, setMetricsMonth] = useState<any>(null);
    const [users, setUsers] = useState<any>(null);

    useEffect(() => {
        if (!data?.retrieveRankingTimeSeries) return;
        const timeSeriesByUser = getTimeSeriesByUser(data.retrieveRankingTimeSeries.timeSeries);
        setUsers(getUsersByIds(data.retrieveRankingTimeSeries.timeSeries));
        setMetricsTotal(getRankAggreations(timeSeriesByUser, 'all'));
        setMetricsToday(getRankAggreations(timeSeriesByUser, 'day'));
        setMetricsWeek(getRankAggreations(timeSeriesByUser, 'week'));
        setMetricsMonth(getRankAggreations(timeSeriesByUser, 'month'));
    }, [data]);

    return { metricsTotal, metricsToday, metricsWeek, metricsMonth, loading, data, users };
};