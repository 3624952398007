import { GET_USERS } from "../../../schemas/users";

import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export type User = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  laborname: string;
  role: string;
  gender: string;
  verifiedAt: string;
};

export const useUsers = () => {
  const { refetch, data, loading } = useQuery(GET_USERS);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (data?.retrieveUsers) setUsers(data?.retrieveUsers);
  }, [data]);

  return { users, loading, refetch };
};
