import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  CountdownCircleTimer,
  useCountdown,
} from "react-countdown-circle-timer";
import React, { createRef, FC, useContext, useEffect, useState } from "react";
import { arrowBackOutline, sendOutline } from "ionicons/icons";
import "./AquisitionCall.scss";
import UIContext from "../../providers/UIProvider";
import { useAuth } from "../../providers/auth";
import { GetReadyModal } from "./Modals/GetReadyModal";
import { QuitModal } from "./Modals/QuitModal";
import { DoneModal } from "./Modals/DoneModal";
import { useConversation } from "./hooks/useConversation";
import leads from "./../../_mocks/leads.json";
import objectionsDentists from "../../_mocks/objections_dentist.json";
import objectionsHelper from "../../_mocks/objections_helper.json";
import { useParams } from "react-router";
import { setDisabled } from "ag-grid-community/dist/lib/utils/dom";

export const AkquisitionCall: FC<any> = () => {
  const { signOut } = useAuth();
  const { dentistConversationId } = useParams<any>();
  const { user, isSignedIn, authTokenGet } = useAuth();

  const contentRef = createRef<HTMLIonContentElement>();
  const { setShowFab } = useContext(UIContext);

  const router = useIonRouter();

  const { createConversation, registerPoints, getConversationById } =
    useConversation();
  const [conversationId, setConversationId] = useState<string | null>(null);

  const {
    path,
    pathLength,
    stroke,
    strokeDashoffset,
    remainingTime,
    elapsedTime,
    size,
    strokeWidth,
  } = useCountdown({ isPlaying: true, duration: 7, colors: "#abc" });

  const [loading, setLoading] = useState<boolean>(false);
  const [stoppedTimerAt, setStoppedTimerAt] = useState<number>();
  const [remainingTimeState, setRemaingTimeState] = useState<number>(60);
  const [selectedMsgOption, setSelectedMsgOption] = useState(null);
  const [receivedLoading, setReceivedLoading] = useState(false);
  const [scrollToBottomState, setScrollToBottomState] = useState(false);
  const [readyModalOpen, setReadyModalOpen] = useState<boolean>(true);
  const [quitModalOpen, setQuitModalOpen] = useState<boolean>(false);
  const [doneModalOpen, setDoneModalOpen] = useState<boolean>(false);

  const [started, setStarted] = useState(false);
  const [conversation, setConversation] = useState<any[]>([]);
  const [currentPoints, setCurrentPoints] = useState<number>(0);
  const [availablePoints, setAvailablePoints] = useState<number>(0);
  const [minimumPoints, setMinimumPoints] = useState<number>(0);
  const [minimumPointsPercentage, setMinimumPointsPercentage] =
    useState<number>(0);
  const [conversationObjections, setConversationObjections] = useState<any[]>(
    []
  );
  const [currentObjection, setCurrentObjection] = useState<any>(null);
  const [currentTreatments, setCurrentTreatments] = useState<any>(null);
  const [timeLeft, setTimeLeft] = useState<any>(null);
  const [activeDentistConversationId, setActiveDentistConversationId] =
    useState<string | null>(null);
  const [isDentistConversation, setIsDentistConversation] =
    useState<boolean>(false);
  const [activeLead, setActiveLead] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  // for starting dentist conversation by routing
  useEffect(() => {
    if (dentistConversationId) {
      setActiveDentistConversationId(dentistConversationId);
      const loadConversation = async () => {
        await generateConversation(dentistConversationId);
        setIsDentistConversation(true);
        setLoading(false);
      };
      loadConversation();
    }
  }, [dentistConversationId]);

  // generate conversation on page load
  useEffect(() => {
    setShowFab(false);
    setLoading(true);
    if (!dentistConversationId) {
      const loadConversation = async () => {
        await generateConversation();
        setLoading(false);
        setDisabled(true);
        setTimeout(() => setDisabled(false), 2000);
      };
      loadConversation();
    }
  }, []);

  // scroll to bottom code
  useEffect(() => {
    if (scrollToBottomState) scrollToBottom();
    setScrollToBottomState(false);
  }, [scrollToBottomState]);

  useEffect(() => {
    setTimeout(() => setScrollToBottomState(true), 1100);
  }, [receivedLoading, conversation]);

  const scrollToBottom = function () {
    contentRef.current?.scrollToBottom(10);
  };
  // end scroll

  const generateAvailablePoints = () => {
    let maxPoints = 0;
    conversationObjections.forEach((objectionItem: any) => {
      const treatmentPoints = objectionItem.treatments.map((treatment: any) => {
        return treatment.points;
      });
      maxPoints += Math.max(...treatmentPoints);
    });
    setAvailablePoints(maxPoints);
    console.log(
      "available points",
      maxPoints,
      minimumPointsPercentage,
      Math.round(minimumPointsPercentage * maxPoints)
    );
    setMinimumPoints(Math.round(minimumPointsPercentage * maxPoints));
  };

  useEffect(() => {
    generateAvailablePoints();
  }, [conversationObjections, minimumPointsPercentage]);

  const generateDentistConversation: (
    pdentistConversationId: string
  ) => void = async (pDentistConversationId) => {
    // set objections based on different step options
    // randomize which step it uses
    const objectionsDentistSteps: any = [];
    objectionsDentists.forEach((objectionItem) => {
      if (!Array.isArray(objectionsDentistSteps[objectionItem.step - 1]))
        objectionsDentistSteps[objectionItem.step - 1] = [];
      objectionsDentistSteps[objectionItem.step - 1].push(objectionItem);
    });
    const selected = objectionsDentistSteps.map((objectionStepItems: any) => {
      if (objectionStepItems.length === 1) return objectionStepItems[0];
      return objectionStepItems[
        Math.floor(Math.random() * objectionStepItems.length)
      ];
    });
    setConversationObjections(selected);
    const conversation = await getConversationById(pDentistConversationId);
    setActiveLead(conversation.lead);
    setMinimumPointsPercentage(conversation.lead.minimumPoints);
    setConversationId(pDentistConversationId);
    setActiveDentistConversationId(null);
    setStarted(true);
    setDisabled(true);
    setTimeout(() => setDisabled(false), 2000);
  };

  const generateConversationHelper: () => void = async () => {
    // shuffle objections and set conversation objections
    const shuffledObjections = objectionsHelper.sort(() => 0.5 - Math.random());
    const countObjections = Math.floor(Math.random() * (6 - 1 + 1) + 1);
    let selected = shuffledObjections.slice(0, countObjections);
    setConversationObjections(selected);

    // setting success criteria and create conversation
    const assistantLeads = leads.filter((lead) => lead.type !== "DENTIST");
    const lead =
      assistantLeads[
        Math.floor(Math.random() * (assistantLeads.length - 1 - 0) + 0)
      ];
    setMinimumPointsPercentage(lead.minimumPoints);
    setActiveLead(lead);
    const createdConversationId = await createConversation(lead.id);
    setActiveLead(lead);
    setConversationId(createdConversationId);
    setStarted(true);
    setDisabled(true);
    setTimeout(() => setDisabled(false), 2000);
  };

  const generateConversation: (
    dentistConversationId?: string | null
  ) => void = async (pDentistConversationId = activeDentistConversationId) => {
    if (pDentistConversationId) {
      await generateDentistConversation(pDentistConversationId);
      return;
    }
    generateConversationHelper();
  };

  const nextObjection = (updatedConversation: any[]) => {
    const nextObjectionIndex = updatedConversation.length / 2;

    const nextObjection = conversationObjections[nextObjectionIndex];

    setConversation([
      ...updatedConversation,
      {
        type: "received",
        msg: nextObjection.msg,
      },
    ]);
    setCurrentTreatments(nextObjection.treatments);
    setDisabled(true);
    setTimeout(() => setDisabled(false), 2000);

    setReceivedLoading(false);
  };

  const calcTimeDeduction = (timeLeft: number) => {
    if (timeLeft < 20) return 0.1;
    if (timeLeft < 10) return 0.2;
    return 0;
  };

  const calcPoints = (points: number, timeDeduction: number) => {
    const result = Math.round(points - points * timeDeduction);
    return result > 0 ? result : 0;
  };

  const submitAnswer = async () => {
    // TODO: save conversation history

    const selectedTreatment = currentTreatments.find(
      (currentTreatment: any) => currentTreatment.id === selectedMsgOption
    );
    const timeDeduction = calcTimeDeduction(timeLeft);
    const points = calcPoints(selectedTreatment.points, timeDeduction);
    const _currentPoints = currentPoints + points;
    setCurrentPoints(_currentPoints);

    setStoppedTimerAt(remainingTimeState);
    const conversationUpdated = [
      ...conversation,
      { type: "sent", msg: selectedTreatment.msg, time: remainingTimeState },
    ];
    setConversation(conversationUpdated);
    setSelectedMsgOption(null);
    setTimeLeft(false);

    setTimeout(() => setScrollToBottomState(true), 5);

    const nextObjectionIndex = conversationUpdated.length / 2;

    // done
    if (nextObjectionIndex >= conversationObjections.length) {
      const pointsHistory = await registerPoints(
        { points: _currentPoints, availablePoints, minimumPoints },
        conversationId
      );
      if (pointsHistory.dentistConversationId)
        setActiveDentistConversationId(pointsHistory.dentistConversationId);
      setDoneModalOpen(true);
      setStarted(false);
      return;
    }

    setReceivedLoading(true);

    setTimeout(() => {
      nextObjection(conversationUpdated);
      setTimeout(() => {
        setScrollToBottomState(true);
      }, 1000);
    }, 1000);
  };

  const handleReadyModalClose = async (start: boolean) => {
    if (activeDentistConversationId) {
      // TODO: handle dentist conversation generation
      setStarted(start);
      const objection = conversationObjections[0];
      setConversation([
        ...conversation,
        {
          type: "received",
          msg: objection.msg,
        },
      ]);

      setCurrentObjection(objection);
      setCurrentTreatments(
        objection.treatments.sort(() => 0.5 - Math.random())
      );
      setReadyModalOpen(false);
      return;
    }
    setStarted(start);
    const objection = conversationObjections[0];
    setConversation([
      ...conversation,
      {
        type: "received",
        msg: objection.msg,
      },
    ]);

    setCurrentObjection(objection);
    setCurrentTreatments(objection.treatments.sort(() => 0.5 - Math.random()));
    setReadyModalOpen(false);
  };

  const handleQuitModalClose = (quit: boolean) => {
    setQuitModalOpen(false);
    if (quit) {
      resetConversation();
      router.push("/app/dashboard", "back", "push");
    }
  };

  const resetConversation = () => {
    setConversationObjections([]);
    setMinimumPoints(0);
    setAvailablePoints(0);
    setCurrentTreatments(0);
    setCurrentPoints(0);
    setConversation([]);
    setStarted(false);
    setIsDentistConversation(false);
    setSelectedMsgOption(null);
    setTimeLeft(false);
  };

  const handleDoneModalClose = async (
    startNewCall?: boolean
  ): Promise<void> => {
    resetConversation();

    if (!startNewCall) {
      setDoneModalOpen(false);
      router.push("/app/dashboard", "back", "push");
      return;
    }

    if (activeDentistConversationId) {
      await generateConversation(activeDentistConversationId);
      setDoneModalOpen(false);
      setReadyModalOpen(true);
      setIsDentistConversation(true);
      setActiveDentistConversationId(null);
      return;
    }

    await generateConversation();
    setDoneModalOpen(false);
    setReadyModalOpen(true);
  };

  const quit = () => {
    setStarted(false);
    setQuitModalOpen(true);
  };

  return (
    <>
      {!loading && (
        <IonPage>
          <IonHeader className="ion-no-border">
            <IonToolbar
              className={"ion-no-margin ion-no-padding ion-align-items-center"}
            >
              <div className="timer-container" slot="end">
                {!readyModalOpen && !receivedLoading && started && (
                  <CountdownCircleTimer
                    onUpdate={(time) => setRemaingTimeState(time)}
                    isPlaying
                    size={46}
                    strokeLinecap={"round"}
                    strokeWidth={6}
                    duration={30}
                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[7, 5, 2, 0]}
                    onComplete={() => {
                      setStarted(false);
                      setDoneModalOpen(true);
                      setTimeLeft(true);
                    }}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                )}
              </div>
              <IonButtons slot="start">
                <div className={"ion-padding"}>
                  <IonIcon icon={arrowBackOutline} onClick={quit} />
                </div>
              </IonButtons>
              <IonText slot={"start"}>
                <h6>Punktestand: {currentPoints}</h6>
              </IonText>
              {/*<IonText slot={"start"} className={'ion-margin-horizontal'}><h3>{conversationObjections.length}</h3></IonText>*/}
            </IonToolbar>
          </IonHeader>
          <IonContent ref={contentRef} className="ion-padding">
            {/*{stoppedTimerAt && <IonText color="tertiary">*/}
            {/*    <h3>You stopped at: {stoppedTimerAt}</h3>*/}
            {/*</IonText>}*/}

            {conversation.map((conversationItem, index) => (
              <div
                key={index}
                className={"message-" + conversationItem.type + "-div"}
              >
                <div className={"message-content-" + conversationItem.type}>
                  {conversationItem.type === "received" && (
                    <IonAvatar>
                      <img src={activeLead?.image} width={"56px"} alt="" />
                    </IonAvatar>
                  )}
                  {conversationItem.type === "sent" && (
                    <IonAvatar>
                      <img
                        src={
                          "/assets/avatar_own/" + (user?.gender ?? "m") + ".svg"
                        }
                        width={"56px"}
                        alt=""
                      />
                    </IonAvatar>
                  )}
                  <IonRow>
                    <div className={"message-" + conversationItem.type}>
                      {conversationItem.msg}
                    </div>
                  </IonRow>
                </div>
              </div>
            ))}
            {receivedLoading && (
              <div className={"message-received-div"}>
                <div className={"message-content-received"}>
                  <IonAvatar>
                    <img src={activeLead?.image} />
                  </IonAvatar>
                  <IonRow>
                    <div className="message-received loader">
                      <IonSpinner name="dots"></IonSpinner>
                    </div>
                  </IonRow>
                </div>
              </div>
            )}
          </IonContent>
          <IonFooter>
            {!!currentTreatments && (
              <div className={"footer-container"}>
                <div className="type-message">
                  <span className="custom-icon-chat-reply-icon"></span>
                  <IonRadioGroup
                    value={selectedMsgOption}
                    onIonChange={(e) => setSelectedMsgOption(e.detail.value)}
                  >
                    {currentTreatments.map(
                      (treatmentItem: any, index: number) => (
                        <IonItem key={index}>
                          <IonLabel className="ion-text-wrap">
                            <div className={"message-option"}>
                              {treatmentItem.msg}
                            </div>
                          </IonLabel>
                          <IonRadio
                            disabled={!started}
                            slot="start"
                            value={treatmentItem.id}
                          ></IonRadio>
                        </IonItem>
                      )
                    )}
                  </IonRadioGroup>
                </div>
                <div style={{ width: "100%" }}>
                  <IonButton
                    disabled={
                      disabled || (receivedLoading && selectedMsgOption != null)
                    }
                    onClick={() => submitAnswer()}
                  >
                    <IonIcon icon={sendOutline} />
                  </IonButton>
                </div>
              </div>
            )}
          </IonFooter>
        </IonPage>
      )}
      <IonModal
        isOpen={readyModalOpen}
        class="modaaal"
        className="modaaal"
        onDidDismiss={() => setReadyModalOpen(false)}
      >
        <GetReadyModal
          lead={activeLead}
          isDentistConversation={isDentistConversation}
          onClose={handleReadyModalClose}
        />
      </IonModal>

      <IonModal
        isOpen={quitModalOpen}
        class="modaaal"
        className="modaaal"
        onDidDismiss={() => {
          setQuitModalOpen(false);
          setStarted(true);
        }}
      >
        <QuitModal onClose={handleQuitModalClose} />
      </IonModal>

      <IonModal
        isOpen={doneModalOpen}
        class="modaaal"
        className="modaaal"
        onDidDismiss={() => {
          setDoneModalOpen(false);
          setStarted(false);
        }}
      >
        <DoneModal
          lead={activeLead}
          dentistConversationId={activeDentistConversationId}
          timeLeft={timeLeft}
          currentPoints={currentPoints}
          availablePoints={availablePoints}
          minimumPoints={minimumPoints}
          onClose={handleDoneModalClose}
        />
      </IonModal>
    </>
  );
};
