import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CREATE_CONVERSATION, GET_CONVERSATION} from "../../../schemas/conversations";
import {REGISTER_POINTS} from "../../../schemas/points";
import {useState} from "react";
import leads from "../../../_mocks/leads.json"

type PointsData = {
    points: number,
    availablePoints: number;
    minimumPoints: number;
};

type PoinstHistoryItem = {
    id: string
    dentistConversationId: string
}

export const useConversation = () => {
    const [createConversationMutation, { error: createConversationError }] = useMutation(CREATE_CONVERSATION);
    const [registerPointsMutation, { error: registerPointsError }] = useMutation(REGISTER_POINTS);
    const { refetch: refetchConversation } = useQuery(GET_CONVERSATION);
    const [conversationId, setConversationId] = useState(null);

    const createConversation = async (leadId: string, leadType: string = "ASSISTANT") : Promise<string> => {
        const conversation = await createConversationMutation({ variables: { leadId, leadType } });

        setConversationId(conversation.data.createConversation.id);
        return conversation.data.createConversation.id;
    };
    const registerPoints = async (pointsData: PointsData, conversationId: string | null) => {
        const point = await registerPointsMutation({ variables: {...pointsData, conversationId } });
        return point.data.registerPoints as PoinstHistoryItem;
    };

    const getConversationById = async (conversationId: string) => {
        const conversationResponse = await refetchConversation({ conversationId });
        const conversation = conversationResponse.data.getConversation;
        const lead = leads.find(lead => lead.id === conversation.leadId);
        return { ...conversation, lead }
    };

    return { createConversation, registerPoints, getConversationById }
};