import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import * as yup from "yup";
import { checkmarkCircle, eye, eyeOff } from "ionicons/icons";
import React, { useState } from "react";
import { useFormik } from "formik";
import { Preferences } from "@capacitor/preferences";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET } from "../../schemas/signup";
import { useParams } from "react-router";
import { Icon } from "@iconify/react";

export const PasswordReset = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const router = useIonRouter();
  const { passwordResetToken } = useParams<any>();
  const [passwordReset, { error: passwordResetError }] =
    useMutation(PASSWORD_RESET);
  const [present] = useIonToast();
  const [referToApp, setReferToApp] = useState<boolean>(false);

  const initialValues = {
    password: "",
    password_confirm: "",
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Bitte gib dein Passwort ein.")
      .min(5, "Das Passwort sollte mindestens 5 Zeichen lang sein"),
    password_confirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Die Passwörter müssen übereinstimmen"
      ),
  });

  const onSubmit: any = async (
    values: any,
    { setStatus, setErrors, errors, status }: any
  ) => {
    await passwordReset({
      variables: {
        token: passwordResetToken,
        password: values.password,
      },
    })
      .then(async (res) => {
        await present({
          message: "Passwort erfolgreich geändert",
          duration: 3000,
          color: "success",
        });
        if (!isPlatform("pwa")) {
          setReferToApp(true);
        } else {
          router.push("/login", "back", "replace");
        }
      })
      .catch(async (err) => {
        await present({
          message: "Etwas ist schief gelaufen!",
          color: "danger",
          duration: 1500,
          position: "bottom",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <IonPage>
      <IonHeader className="login-header ion-no-border">
        <IonToolbar>
          {/*<IonButtons slot="start">*/}
          {/*  <IonBackButton defaultHref="/" />*/}
          {/*</IonButtons>*/}
        </IonToolbar>
      </IonHeader>
      <IonContent className="login ion-padding-horizontal" fullscreen>
        <IonGrid className="main-grid" style={{ height: "auto" }}>
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <img src="/assets/imgs/logo/logo.webp" width={"54px"} alt="" />
          </IonRow>
        </IonGrid>

        {referToApp && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4rem",
            }}
          >
            <div
              style={{
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Icon
                icon="icon-park-outline:success"
                color={"#312783"}
                width={"112px"}
                height={"112px"}
              />
            </div>
            <h2 style={{ marginTop: "4rem" }} className={"ion-text-center"}>
              Dein Konto wurde erfolgreich verifiziert, du kannst nun in der App
              fortfahren.
            </h2>
          </div>
        )}

        {!referToApp && <h4>Du kannst nun ein neues Passwort vergeben</h4>}

        {!referToApp && (
          <form onSubmit={formik.handleSubmit}>
            <div className="input-holder">
              <IonLabel className="input-label">Passwort</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Enter Password"
                  name={"password"}
                  type={!showPassword ? "password" : "text"}
                  value={formik.values.password}
                  onIonChange={formik.handleChange}
                />
                <IonIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={!showPassword ? eye : eyeOff}
                  slot="end"
                />
              </IonItem>
              {formik.errors?.password && (
                <IonInput className={"ion-padding"} color="danger">
                  <span slot={"error"}>{formik.errors?.password}</span>
                </IonInput>
              )}
            </div>

            <div className="input-holder">
              <IonLabel className="input-label">Passwort wiederholen</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Passwort wiederholen"
                  type={!showPassword ? "password" : "text"}
                  name={"password_confirm"}
                  value={formik.values.password_confirm}
                  onIonChange={formik.handleChange}
                />
                <IonIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={!showPassword ? eye : eyeOff}
                  slot="end"
                />
              </IonItem>
              {formik.errors?.password_confirm && (
                <IonInput className={"ion-padding"} color="danger">
                  <span slot={"error"}>{formik.errors?.password_confirm}</span>
                </IonInput>
              )}
            </div>

            <div className="btn-holder">
              <IonButton
                expand="block"
                className="login-button"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <IonSpinner name="circular"></IonSpinner>
                ) : (
                  "Passwort zurücksetzen"
                )}
              </IonButton>
            </div>
          </form>
        )}

        {!referToApp && (
          <div className="rem-holder">
            <IonButton
              onClick={() => router.push("/login")}
              className="forgot-button"
            >
              Zurück zum Login
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
