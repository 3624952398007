import {useMutation, useQuery} from "@apollo/client";
import {CREATE_CONVERSATION, OPEN_DENTIST_CONVERSATIONS, RETRIEVE_CONVERSATIONS} from "../../../schemas/conversations";
import leads from "../../../_mocks/leads.json"
import {useEffect, useState} from "react";

export const useRetrieveConversations = () => {
    const [conversationsHistory, setConversationsHistory] = useState<any[]>([]);
    const { loading, error, data } = useQuery(RETRIEVE_CONVERSATIONS);

    useEffect(() => {
        if (data) setConversationsHistory(data?.conversations.map((conversation: any) => ({...conversation, lead: leads.find(lead => lead.id === conversation.leadId) })));
    }, [data]);

    return { loading, error, data, conversationsHistory }
};