import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { checkmarkCircle, eye, eyeOff } from "ionicons/icons";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../../providers/auth";
import { Header } from "../../components/Header";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE } from "../../schemas/profile";
import UIContext from "../../providers/UIProvider";
import "./profile.scss";
import { Exception } from "sass";

export const Profile = () => {
  const { setShowFab } = useContext(UIContext);

  useEffect(() => {
    setShowFab(true);
  }, []);

  const [updateProfileMutation, { error: updateProfileError }] =
    useMutation(UPDATE_PROFILE);

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { user, isSignedIn, authTokenGet } = useAuth();
  const [present] = useIonAlert();
  const { signOut } = useAuth();
  const router = useIonRouter();

  const initialValues = {
    email: user?.email,
    password: "",
    password_confirm: "",
    laborname: "",
    firstname: "",
    lastname: "",
  };

  const validationSchema = yup.object({
    laborname: yup.string().trim().min(3),
    firstname: yup.string().trim().min(3),
    lastname: yup.string().trim().min(3),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),
    password: yup
      .string()
      .oneOf(
        [yup.ref("password_confirm"), null],
        "Passwörter müssen übereinstimmen"
      ),
    password_confirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
  });

  const removeEmptyStrings = (obj: any) => {
    for (let key in obj) {
      if (typeof obj[key] === "string" && obj[key].trim() === "") {
        delete obj[key];
      }
    }
    return obj;
  };

  const onSubmit: any = async (
    values: any,
    { setStatus, setErrors, errors, status }: any
  ) => {
    setLoading(true);

    try {
      await updateProfileMutation({ variables: removeEmptyStrings(values) });
      values.email !== user?.email && router.push("/verify", "back", "push");
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    // @ts-ignore
    formik.setValues({
      laborname: user?.laborname ?? "",
      firstname: user?.firstname ?? "",
      lastname: user?.lastname ?? "",
      email: user?.email ?? "",
    });
  }, [user]);

  return (
    <IonPage>
      <Header title={"Profil"} />
      <IonContent
        className="login ion-padding-horizontal ion-align-items-center"
        fullscreen
      >
        <div>
          <div className={"ion-flex ion-justify-content-center"}>
            <IonAvatar>
              <img
                src={"/assets/avatar_own/" + (user?.gender ?? "m") + ".svg"}
                alt=""
              />
            </IonAvatar>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="input-holder">
              <IonLabel className="input-label">Laborname</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Laborname angeben"
                  type="text"
                  name={"laborname"}
                  value={formik.values.laborname}
                  onIonChange={formik.handleChange}
                />
                {/*<IonIcon*/}
                {/*    className="verify-tick"*/}
                {/*    icon={checkmarkCircle}*/}
                {/*    slot="end"*/}
                {/*/>*/}
              </IonItem>
            </div>

            <div className="input-holder">
              <IonLabel className="input-label">Vorname</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Vorname eingeben"
                  type="text"
                  name={"firstname"}
                  value={formik.values.firstname}
                  onIonChange={formik.handleChange}
                />
                {/*<IonIcon*/}
                {/*    className="verify-tick"*/}
                {/*    icon={checkmarkCircle}*/}
                {/*    slot="end"*/}
                {/*/>*/}
              </IonItem>
            </div>

            <div className="input-holder">
              <IonLabel className="input-label">Nachname</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Nachname eingeben"
                  type="text"
                  name={"lastname"}
                  value={formik.values.lastname}
                  onIonChange={formik.handleChange}
                />
                {/*<IonIcon*/}
                {/*    className="verify-tick"*/}
                {/*    icon={checkmarkCircle}*/}
                {/*    slot="end"*/}
                {/*/>*/}
              </IonItem>
            </div>

            <div className="input-holder">
              <IonLabel className="input-label">E-Mail</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Enter E-Mail"
                  type="text"
                  name={"email"}
                  value={formik.values.email}
                  onIonChange={formik.handleChange}
                />
                {/*<IonIcon*/}
                {/*    className="verify-tick"*/}
                {/*    icon={checkmarkCircle}*/}
                {/*    slot="end"*/}
                {/*/>*/}
              </IonItem>
            </div>
            <div className="input-holder">
              <IonLabel className="input-label">Passwort</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Passwort eingeben"
                  type={!showPassword ? "password" : "text"}
                  name={"password"}
                  value={formik.values.password}
                  onIonChange={formik.handleChange}
                />
                <IonIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={!showPassword ? eye : eyeOff}
                  slot="end"
                />
              </IonItem>
            </div>
            <div className="input-holder">
              <IonLabel className="input-label">Passwort wiederholen</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Passwort wiederholen"
                  type={!showPassword ? "password" : "text"}
                  name={"password_confirm"}
                  value={formik.values.password_confirm}
                  onIonChange={formik.handleChange}
                />
                <IonIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={!showPassword ? eye : eyeOff}
                  slot="end"
                />
              </IonItem>
            </div>
            <div className="btn-holder">
              <IonButton
                expand="block"
                className="profile-button"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <IonSpinner name="circular"></IonSpinner>
                ) : (
                  "Aktualisieren"
                )}
              </IonButton>
            </div>
          </form>

          <div className="bottom-holder ion-margin-bottom flex al-center jc-center ion-text-center ion-margin-bottom">
            <IonButton className="bottom-btn-span" onClick={() => signOut()}>
              Abmelden
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
