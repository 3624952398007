import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
  isPlatform,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import React, { FC, useContext, useEffect, useState } from "react";
import UIContext from "../../providers/UIProvider";
import { Header } from "../../components/Header";
import { Icon } from "@iconify/react";
import { useParams } from "react-router";
import { useMutation } from "@apollo/client";
import { VERIFY } from "../../schemas/signup";

export const VerifyToken: FC<any> = () => {
  const { token } = useParams<any>();
  const [verifyToken, { error }] = useMutation(VERIFY);
  const { setShowFab } = useContext(UIContext);
  const [present] = useIonToast();
  const router = useIonRouter();

  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    setShowFab(false);
    verify();
  }, []);

  useEffect(() => {
    if (isPlatform("pwa")) {
      router.push("/intro", "back", "push");
      (async () => {
        await present({
          message: "Dein Profil wurde efolgreich verifiziert!",
          color: "success",
          duration: 1500,
          position: "bottom",
        });
      })();
    }
  }, [verified]);

  const verify = async () => {
    const verification = await verifyToken({
      variables: {
        token,
      },
    })
      .then(() => {
        setVerified(true);
        if (isPlatform("pwa")) {
          router.push("/intro", "back", "push");
          (async () => {
            await present({
              message: "Dein Profil wurde efolgreich verifiziert!",
              color: "success",
              duration: 1500,
              position: "bottom",
            });
          })();
        }
        setLoading(false);
      })
      .catch(async (e) => {
        await present({
          message: "Es ist ein Fehler aufgetreten!",
          color: "danger",
          duration: 1500,
          position: "bottom",
        });
        setLoading(false);
      });
  };

  return (
    <IonPage>
      <Header showIntro={false} title={"Verifizierung"} />
      <IonContent className="login ion-padding-horizontal" fullscreen>
        {loading && !verified && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4rem",
            }}
          >
            <div className={"ion-flex ion-justify-content-center"}>
              <Icon
                color={"#312783"}
                width={"56px"}
                icon={"material-symbols:mark-email-read-outline"}
              />
            </div>
            <div
              style={{
                marginTop: "4rem",
              }}
            >
              <IonSpinner name="circular"></IonSpinner>
            </div>

            <h2 style={{ marginTop: "4rem" }} className={"ion-text-center"}>
              Dein Konto wird nun verifiziert
            </h2>
          </div>
        )}

        {!loading && verified && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4rem",
            }}
          >
            <div
              style={{
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Icon
                icon="icon-park-outline:success"
                color={"#312783"}
                width={"112px"}
                height={"112px"}
              />
            </div>
            <h2 style={{ marginTop: "4rem" }} className={"ion-text-center"}>
              Dein Konto wurde erfolgreich verifiziert, du kannst nun in der App
              fortfahren.
            </h2>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
