import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
// @ts-ignore
import { Swiper, SwiperSlide } from "swiper/react";
// @ts-ignore
import { Autoplay, Keyboard, Pagination, Zoom } from "swiper";

import "./Intro.css";

import "@ionic/react/css/ionic-swiper.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useContext, useEffect } from "react";
import UIContext from "../../providers/UIProvider";
import { useMutation } from "@apollo/client";
import { FINISH_INTRO } from "../../schemas/intro";
import { AuthProvider, useAuth } from "../../providers/auth";

const Intro: React.FC = () => {
  const router = useIonRouter();
  const { setShowFab } = useContext(UIContext);
  const { user } = useAuth();
  const [finishIntroMutation, { error }] = useMutation(FINISH_INTRO);

  useEffect(() => {
    setShowFab(false);
  }, []);

  const finish = async () => {
    if (user.introDoneAt) {
      router.push("/app/dashboard", "back", "push");
      return;
    }
    finishIntroMutation().then(async () => {
      router.push("/app/dashboard", "back", "push");
    });
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={finish} className="skip-button">
              Überspringen
            </IonButton>
          </IonButtons>
          <IonRow className={"ion-padding"} slot={"start"}>
            <img src="/assets/imgs/logo/logo.webp" height={36} alt="" />
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding intro" fullscreen>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={true}
          pagination={true}
        >
          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/welcome.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">
                Wilkommen in der EDC Akquise Trainer App
              </h4>
              <p className="sm-detail ion-text-wrap">
                Hiermit kannst du die Akquise Gespräche mit Zahnarzthelfern und
                Zahnärzten trainieren
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="/assets/imgs/intros/begin-conversation.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">
                Beginne mit einer Konversation
              </h4>
              <p className="sm-detail ion-text-wrap">
                Mit dem "+" in der unteren Leiste kannst du diese beginnen
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/drive-to-praxis.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">
                Zahnarztpraxis anfahren
              </h4>
              <p className="sm-detail ion-text-wrap">
                Bei der Konversation fährst du eine Zahnarztpraxis an und
                sprichst mit einer Zahnarzthelferin
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/akquisition-call.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">Gespräch führen</h4>
              <p className="sm-detail ion-text-wrap">
                Die Zahnarzthelferin wird dir nun verschiedene Enwände
                vorbringen, die es zu behandeln gilt. Dies kannst du mit den
                Unten aufgeführten Texten auswählen und abschicken.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/timer.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">Zeit</h4>
              <p className="sm-detail ion-text-wrap">
                Behalte dabei die Zeit im Auge, du hast 30 Sekunden um deine
                Anwort auszuwählen, sonst ist das Gespräch beendet. Merke: Je
                schneller du anwortest, desto mehr Punkte bekommst du für deine
                Antwort.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/dentist-succeed.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">Zahnarztgespräch</h4>
              <p className="sm-detail ion-text-wrap">
                Wenn du die Zahnarzthelferin überzeugt hast, wird dir ein
                Gespräch mit einem Zahnarzt / einer Zahnärztin freigeschalten.
                Du kannst dich nun entscheiden, ob dies direkt angehst oder
                erstmal aufhörst.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/conversations.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">Konversationen</h4>
              <p className="sm-detail ion-text-wrap">
                In dem Bereich Konversationen findest du offene
                Zahnarztgespräche, die du von hier aus beginnen kannst.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/prev-conversations.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">Konversationen</h4>
              <p className="sm-detail ion-text-wrap">
                Zudem kannst du hier auch sehen wie viel Punkte du in deinen
                vergangenen Gesprächen erzielt hast
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide-content ion-text-center ion-padding-horizontal">
              <div className="img-holder ion-text-center animate__animated animate__bounce">
                <img
                  src="assets/imgs/intros/ranking.png"
                  style={{ height: "352px" }}
                />
              </div>
              <h4 className="sm-title ion-text-wrap">Rangliste</h4>
              <div className="sm-detail ion-text-wrap ion-margin">
                <p>
                  Miss dich mit den anderen Benutzern des Akquisetrainers und
                  sieh dir an wo du im Vergleich stehst
                </p>
                <IonButton onClick={finish}>Loslegen</IonButton>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default Intro;
