import { FC, useContext, useEffect, useState } from "react";
import UIContext from "../../providers/UIProvider";
import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isPlatform } from "@ionic/react";

type explanationItemType = {
  title: string;
  description: string;
};

const explanationItems: {
  ios: explanationItemType[];
  android: explanationItemType[];
} = {
  ios: [
    {
      title: "Öffne diesen Link in Safari",
      description:
        "Öffne den Link im Safari Browser damit du die App nach Anleitung installieren kannst.",
    },
    {
      title: "Laden Sie die EDC Akquise App herunter",
      description:
        "Um die App herunterzuladen drücke zuerst auf das Symbol mit dem Pfeil nach oben.",
    },
    {
      title: "Zum Home-Bildschirm hinzufügen",
      description: "Drücke auf das Element mit dem + 'Zum Home-Bildschirm' .",
    },
    {
      title: "Hinzufügen",
      description:
        "Drücke oben rechts auf Hinzufügen und die App wird ihrem Home Bildschirm hinzugefügt.",
    },
  ],
  android: [
    {
      title: "Öffne diesen Link in Chrome",
      description:
        "Öffne den Link im Chrome Browser damit du die App nach Anleitung installieren kannst.",
    },
    {
      title: "Laden Sie die EDC Akquise App herunter",
      description:
        "Um die App herunterzuladen drücke zuerst auf das Symbol oben Rechts mit den 3 Punkten.",
    },
    {
      title: "Zum Startbildschirm hinzufügen",
      description:
        "Drücke auf das Element mit der Bezeichnung 'App installieren' .",
    },
    {
      title: "Installieren",
      description:
        "Drücke nun auf Installieren und die App wird ihrem Startbildschirm hinzugefügt.",
    },
  ],
};

export const PWAExplanation: FC = () => {
  const { setShowFab } = useContext(UIContext);
  const [platform, setPlatform] = useState<"android" | "ios">("android");

  useEffect(() => {
    setShowFab(false);
  }, []);

  useEffect(() => {
    if (isPlatform("ios") || isPlatform("iphone")) {
      setPlatform("ios");
    }
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding intro" fullscreen>
        <IonGrid
          className="main-grid ion-no-padding"
          style={{ height: "auto" }}
        >
          <IonRow className="main-row ion-no-padding ion-justify-content-center ion-align-items-center">
            <img src="/assets/imgs/logo/logo.webp" height={36} alt="Logo" />
          </IonRow>
        </IonGrid>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={true}
          pagination={true}
        >
          {explanationItems[platform].map((explanationItem, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content ion-text-center ">
                <div className="img-holder ion-text-center animate__animated animate__bounce">
                  <img
                    src={
                      "assets/imgs/pwa-explainer/" +
                      platform +
                      "/explainer-" +
                      index +
                      ".png"
                    }
                    style={{ height: "386px", margin: 24 }}
                  />
                </div>
                <h4 className="sm-title ion-text-wrap">
                  {explanationItem.title}
                </h4>
                {index == 0 && (
                  <a href={"https://akquise-app.ehrich-dc.de"} target="_blank">
                    Link zur App
                  </a>
                )}
                <p className="sm-detail ion-text-wrap">
                  {explanationItem.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};
