import {FC} from "react";
import {IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow,} from "@ionic/react";
import "./QuitModal.scss"

export const QuitModal: FC<{ onClose?: any }> = ({onClose}) => {

    const quit = () => {
        onClose(false);
    };

    return (
        <IonPage>
            <IonContent fullscreen className="modaaal">
                <IonGrid className="main-grid">
                    <IonRow className="main-row ion-justify-content-center ion-align-items-center">
                        <div className="the-holder ion-padding">
                            <IonRow className="title-row">
                                <IonCol size="8" class="ion-text-left">
                                    <h4>Conversation</h4>
                                </IonCol>
                                <IonCol size="4" class="ion-text-right" onClick={() => {
                                    quit()
                                }}>
                                    <p>abbrechen</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className={'ion-justify-content-center ion-text-center'}>
                                <p>Willst wirklich das Gespräch mit der Zahnarzthelferin beenden ?</p>
                                <IonButton onClick={() => {  onClose(true)  }}>Gespräch beenden</IonButton>
                            </IonRow>
                        </div>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

