import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import * as yup from "yup";
import { checkmarkCircle } from "ionicons/icons";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_APPLICATION } from "../../schemas/signup";
import { Icon } from "@iconify/react";

export const PasswordResetApplication = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useIonRouter();
  const [resetWithLaborName, setResetWithLaborName] = useState<boolean>(false);
  const [passwordResetApplication, { error: passwordResetApplicationError }] =
    useMutation(PASSWORD_RESET_APPLICATION);
  const [present] = useIonToast();
  const [referToApp, setReferToApp] = useState<boolean>(false);

  const initialValues = {
    laborname: "",
    email: "",
  };

  const validationSchema = yup.object().shape(
    {
      laborname: yup
        .string()
        .trim()
        .min(3, "Der Laborname sollte mindestens 3 Zeichen lang sein.")
        .when("email", {
          is: (email: string) => !email || email.length === 0,
          then: yup.string().required("Bitte gib einen Labornamen ein."),
        }),
      email: yup
        .string()
        .trim()
        .email("Bitte gib eine gültige E-Mail Adresse ein.")
        .when("laborname", {
          is: (laborname: string) => !laborname || laborname.length === 0,
          then: yup.string().required("Bitte gib eine E-Mail Adresse ein."),
        }),
    },
    ["laborname", "email"] as any
  );

  const onSubmit: any = async (
    values: any,
    { setStatus, setErrors, errors, status }: any
  ) => {
    await passwordResetApplication({
      variables: {
        email: values.email,
        token: values.token,
      },
    })
      .then(async (res) => {
        await present({
          message:
            "Es wurde eine E-Mail mit dem Link zum Zurücksetzen des Passworts an deine E-Mail Adresse gesendet.",
          duration: 3000,
          color: "success",
        });
        router.push("/login", "back", "push");
      })
      .catch(async (err) => {
        await present({
          message: "Etwas ist schief gelaufen!",
          color: "danger",
          duration: 1500,
          position: "bottom",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <IonPage>
      <IonHeader className="login-header ion-no-border">
        <IonToolbar>
          {/*<IonButtons slot="start">*/}
          {/*  <IonBackButton defaultHref="/" />*/}
          {/*</IonButtons>*/}
        </IonToolbar>
      </IonHeader>
      <IonContent className="login ion-padding-horizontal" fullscreen>
        <IonGrid className="main-grid" style={{ height: "auto" }}>
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <img src="/assets/imgs/logo/logo.webp" width={"54px"} alt="" />
          </IonRow>
        </IonGrid>

        {referToApp && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4rem",
            }}
          >
            <div
              style={{
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Icon
                icon="icon-park-outline:success"
                color={"#312783"}
                width={"112px"}
                height={"112px"}
              />
            </div>
            <h2 style={{ marginTop: "4rem" }} className={"ion-text-center"}>
              Dein Konto wurde erfolgreich verifiziert, du kannst nun in der App
              fortfahren.
            </h2>
          </div>
        )}

        {!referToApp && (
          <form onSubmit={formik.handleSubmit}>
            {resetWithLaborName && (
              <div className="input-holder">
                <IonLabel className="input-label">Laborname</IonLabel>
                <IonItem className="input-item">
                  <IonInput
                    className="form-input"
                    placeholder="Labornamen eingeben"
                    name={"laborname"}
                    type="text"
                    value={formik.values.laborname}
                    onIonChange={formik.handleChange}
                  />
                  {!formik.errors.laborname &&
                    formik.values.laborname !== "" && (
                      <IonIcon
                        className="verify-tick"
                        icon={checkmarkCircle}
                        slot="end"
                      />
                    )}
                </IonItem>
                {formik.errors?.laborname && (
                  <IonInput className={"ion-padding"} color="danger">
                    <span slot={"error"}>{formik.errors?.laborname}</span>
                  </IonInput>
                )}
              </div>
            )}

            {!resetWithLaborName && (
              <div className="input-holder">
                <IonLabel className="input-label">E-Mail</IonLabel>
                <IonItem className="input-item">
                  <IonInput
                    className="form-input"
                    placeholder="E-Mail eingeben"
                    name={"email"}
                    type="text"
                    value={formik.values.email}
                    onIonChange={formik.handleChange}
                  />
                  {!formik.errors.email && formik.values.email !== "" && (
                    <IonIcon
                      className="verify-tick"
                      icon={checkmarkCircle}
                      slot="end"
                    />
                  )}
                </IonItem>
                {formik.errors?.email && (
                  <IonInput className={"ion-padding"} color="danger">
                    <span slot={"error"}>{formik.errors?.email}</span>
                  </IonInput>
                )}
              </div>
            )}

            <div className="btn-holder">
              <IonButton
                expand="block"
                className="login-button"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <IonSpinner name="circular"></IonSpinner>
                ) : (
                  "Passwort zurücksetzen"
                )}
              </IonButton>
            </div>
          </form>
        )}

        {!referToApp && (
          <div className="rem-holder">
            <IonButton
              onClick={() => setResetWithLaborName(!resetWithLaborName)}
              className="forgot-button"
            >
              Das Passwort mit{" "}
              {!resetWithLaborName ? "dem Labornamen" : "der E-Mail"}{" "}
              zurücksetzen
            </IonButton>
          </div>
        )}

        {!referToApp && (
          <div className="rem-holder">
            <IonButton
              onClick={() => router.push("/login")}
              className="forgot-button"
            >
              Zurück zum Login
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
