import { FC, useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./GetReadyModal.scss";

export const GetReadyModal: FC<{
  lead: any;
  isDentistConversation: boolean;
  onClose?: any;
}> = ({ lead, isDentistConversation, onClose }) => {
  const router = useIonRouter();

  const quit = () => {
    onClose(false);
    router.push("/app/profile", "back", "push");
  };

  return (
    <IonPage>
      <IonContent fullscreen className="modaaal">
        <IonGrid className="main-grid">
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <div className="the-holder ion-padding">
              <IonRow className="title-row">
                <IonCol size="8" class="ion-text-left">
                  <h4>Bist du bereit ?</h4>
                </IonCol>
                <IonCol
                  size="4"
                  class="ion-text-right"
                  onClick={() => {
                    quit();
                  }}
                >
                  <p>abbrechen</p>
                </IonCol>
              </IonRow>
              <IonRow className={"ion-justify-content-center"}>
                <h6>Du sprichst mit {lead?.name}</h6>
              </IonRow>
              <IonRow className={"ion-justify-content-center"}>
                <div>
                  <img src={"/" + lead?.image} height={"124px"} alt="" />
                </div>
              </IonRow>
              <IonRow className={"ion-justify-content-center ion-text-center"}>
                <IonButton
                  onClick={() => {
                    onClose(true);
                  }}
                >
                  Gespräch starten
                </IonButton>
              </IonRow>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
