import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query users {
    retrieveUsers {
      id
      email
      firstname
      lastname
      laborname
      gender
      role
      verifiedAt
      introDoneAt
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(data: { userId: $userId })
  }
`;

export const RESEND_USER_VERIFICATION = gql`
  mutation resendUserVerificationMail($userId: String!) {
    resendUserVerificationMail(data: { userId: $userId }) {
      id
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($userId: String!) {
    verifyUser(data: { userId: $userId }) {
      id
      email
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation editRole($userId: String!, $role: String!) {
    editRole(data: { userId: $userId, role: $role }) {
      id
      email
      role
    }
  }
`;
