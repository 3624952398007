import {
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../providers/UIProvider";
import { Header } from "../../components/Header";
import { WidgetLineChart } from "./components/WidgetLineChart";
import { WidgetSuccessRate } from "./components/WidgetSuccessRate";
import { WidgetPointsHistory } from "./components/WidgetPointsHistory";
import { useMetrics } from "./hooks/useMetrics";
import { useAuth } from "../../providers/auth";
import { UserManagement } from "./components/DashboardViews/UserManagement";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Dashboard = () => {
  const { setShowFab } = useContext(UIContext);
  const [activeSegment, setActiveSegment] = useState<string>("today");
  const [activeChart, setActiveChart] = useState<string>("points");
  const [currentMetricsData, setCurrentMetricsData] = useState<any>(null);
  const [dashboardView, setDashboardView] = useState<string>("training-stats");
  const { metricsTotal, metricsToday, metricsWeek, metricsMonth, loading } =
    useMetrics();
  const { user } = useAuth();

  useEffect(() => {
    switch (activeSegment) {
      case "today":
        setCurrentMetricsData(metricsToday);
        break;
      case "week":
        setCurrentMetricsData(metricsWeek);
        break;
      case "month":
        setCurrentMetricsData(metricsMonth);
        break;
      case "all":
        setCurrentMetricsData(metricsTotal);
        break;
    }
  }, [metricsTotal]);

  const segmentChanged = (e: any) => {
    setActiveSegment(e.detail.value);
    switch (e.detail.value) {
      case "today":
        setCurrentMetricsData(metricsToday);
        break;
      case "week":
        setCurrentMetricsData(metricsWeek);
        break;
      case "month":
        setCurrentMetricsData(metricsMonth);
        break;
      case "all":
        setCurrentMetricsData(metricsTotal);
        break;
    }
  };

  const dashboardViewChanged = (e: any) => {
    setDashboardView(e.detail.value);
  };

  const chartChanged = (e: any) => {
    setActiveChart(e.detail.value);
  };

  useEffect(() => {
    setShowFab(true);
  }, []);

  if (loading) return <></>;

  return (
    <IonPage>
      <Header title={"Dashboard"} />
      <IonContent className="ion-padding-horizontal" fullscreen>
        {user?.role === "ADMIN" && (
          <IonSegment
            onIonChange={(e) => dashboardViewChanged(e)}
            value={dashboardView}
          >
            <IonSegmentButton value={"training-stats"}>
              <IonLabel>Trainings Resultate</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={"user-management"}>
              <IonLabel>Benutzerverwaltung</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        )}
        {dashboardView === "user-management" && <UserManagement />}
        {dashboardView === "training-stats" && (
          <>
            <IonSegment
              onIonChange={(e) => segmentChanged(e)}
              value={activeSegment}
            >
              <IonSegmentButton value={"today"}>
                <IonLabel>Heute</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={"week"}>
                <IonLabel>Woche</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={"month"}>
                <IonLabel>Monat</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={"all"}>
                <IonLabel>Gesamt</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <IonGrid>
              <IonRow>
                <IonCol size={"12"}>
                  {activeChart === "points" && (
                    <WidgetLineChart
                      segment={activeSegment}
                      metricsData={currentMetricsData}
                    />
                  )}
                  {activeChart === "successRate" && (
                    <WidgetSuccessRate
                      segment={activeSegment}
                      metricsData={currentMetricsData}
                    />
                  )}
                  {activeChart === "pointsHistory" && (
                    <WidgetPointsHistory metricsData={currentMetricsData} />
                  )}
                  <IonSegment
                    onIonChange={(e) => chartChanged(e)}
                    value={activeChart as any}
                  >
                    <IonSegmentButton value={"points"}>
                      <IonLabel>Punkte</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={"successRate"}>
                      <IonLabel>Erfolgsrate</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={"pointsHistory"}>
                      <IonLabel>Punktehistorie</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
        {/*<IonGrid>*/}
        {/*    <IonRow>*/}
        {/*        <div className={"chart-container"}>*/}
        {/*            <ReactApexChart*/}
        {/*                // @ts-ignore*/}
        {/*                options={historyChart.options}*/}
        {/*                series={historyChart.series}*/}
        {/*                type="bar"*/}
        {/*                height={350}*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*    </IonRow>*/}
        {/*    <IonRow>*/}
        {/*        <div className={"chart-container"}>*/}
        {/*            <ReactApexChart*/}
        {/*                // @ts-ignore*/}
        {/*                options={lineChartVisualization.options}*/}
        {/*                series={lineChartVisualization.series}*/}
        {/*                type="area"*/}
        {/*                height={350}*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*    </IonRow>*/}
        {/*</IonGrid>*/}
      </IonContent>
    </IonPage>
  );
};
