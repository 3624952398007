import { gql } from "@apollo/client"
export const CREATE_CONVERSATION = gql`
    mutation createConversation (
        $leadId: String!,
        $leadType: String,
    ) {
        createConversation(
            data: {
                leadId: $leadId,
                leadType: $leadType,
            }
        ) {
            id
        }
    }
`;

export const RETRIEVE_CONVERSATIONS = gql`
    query conversations {
        conversations {
            id
            leadType
            leadId
            points {
                id
                availablePoints
                points
                minimumPoints
            }
            createdAt
            updatedAt
        }
    }
`;

export const OPEN_DENTIST_CONVERSATIONS = gql`
    query openDentistConversations {
        openDentistConversations {
            id
            leadId
            leadType
            createdAt
            updatedAt
        }
    }
`;

export const GET_CONVERSATION = gql`
    query getConversation (
        $conversationId: String!
    ) {
        getConversation(
            conversationId: $conversationId,
        ) {
            id
            leadId
            leadType
            createdAt
            updatedAt
        }
    }
`;