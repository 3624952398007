import React, {FC, PropsWithChildren, useState} from 'react';

// create the context
export const Context = React.createContext<any>(undefined);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...

export const UIProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    // the reactive values
    const [showFab, setShowFab] = useState(false);
    const [roleMode, setRoleMode] = useState('USER');

    // the store object
    let state = {
        showFab,
        setShowFab,
        roleMode,
        setRoleMode
    };

    // wrap the application in the provider with the initialized context
    return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context;
