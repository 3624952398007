import {useMutation, useQuery} from "@apollo/client";
import {CREATE_CONVERSATION, OPEN_DENTIST_CONVERSATIONS} from "../../../schemas/conversations";
import leads from "../../../_mocks/leads.json"
import {useEffect, useState} from "react";

export const useOpenDentistConversations = () => {
    const [conversations, setDentistConversations] = useState<any[]>([]);
    const { loading, error, data } = useQuery(OPEN_DENTIST_CONVERSATIONS);

    useEffect(() => {
        // map conversation objects to leads
        if (data) setDentistConversations(data?.openDentistConversations.map((conversation: any) => ({...conversation, lead: leads.find(lead => lead.id === conversation.leadId) })));
    }, [data]);

    return { loading, error, data, conversations }
};