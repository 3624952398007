import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import React, { FC, useContext, useEffect } from "react";
import UIContext from "../../providers/UIProvider";
import { Header } from "../../components/Header";
import { Icon } from "@iconify/react";
import { useAuth } from "../../providers/auth";
import { useMutation } from "@apollo/client";
import { RESEND_VERIFICATION_EMAIL } from "../../schemas/signup";

export const Verification: FC<any> = () => {
  const { setShowFab } = useContext(UIContext);
  const [resendVerificationEmail, { error }] = useMutation(
    RESEND_VERIFICATION_EMAIL
  );

  const [present] = useIonToast();

  const router = useIonRouter();

  const { signOut, reloadUser } = useAuth();

  useEffect(() => {
    setShowFab(false);
  }, []);

  const resend = async () => {
    await resendVerificationEmail().then(async () => {
      await present({
        message: "Die Verifizierungsmail wurde efolgreich neu gesendet!",
        color: "success",
        duration: 1500,
        position: "bottom",
      });
    });
  };

  const checkVerification = async () => {
    const user = await reloadUser().then(async (user: any) => {
      if (user?.verifiedAt) {
        await present({
          message: "Dein Konto ist erfolgreich verifiziert!",
          color: "success",
          duration: 1500,
          position: "bottom",
        });
        router.push("/intro", "back", "push");
      } else {
        await present({
          message: "Dein Konto wurde noch nicht verifiziert!",
          color: "danger",
          duration: 1500,
          position: "bottom",
        });
      }
    });
  };

  return (
    <IonPage>
      <Header showIntro={false} title={"Verifizierung"} />
      <IonContent className="login ion-padding-horizontal" fullscreen>
        <IonGrid className="main-grid" style={{ height: "auto" }}>
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <img src="/assets/imgs/logo/logo.webp" width={"54px"} alt="" />
          </IonRow>
        </IonGrid>

        <h2 className={"ion-text-center"}>
          Bitte bestätige deine E-Mail Addresse
        </h2>
        <p className={"ion-text-center"}>
          Wir haben dir eine E-Mail gesendet um deine E-Mail zu bestätigen und
          dein Konto zu verizieren
        </p>

        <div className={"ion-flex ion-justify-content-center"}>
          <Icon
            width={128}
            height={128}
            icon={"material-symbols:mark-email-read-outline"}
          />
        </div>

        <div className="btn-holder">
          <IonButton onClick={resend} expand="block">
            Erneut senden
          </IonButton>
        </div>

        <div className="btn-holder">
          <IonButton onClick={checkVerification} expand="block">
            Verifizierung prüfen
          </IonButton>
        </div>

        <div className="bottom-holder flex al-center jc-center ion-text-center ion-margin-bottom">
          <IonButton className="bottom-btn-span" onClick={() => signOut()}>
            Abmelden
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};
