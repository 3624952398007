import { IonItem, IonLabel, IonText } from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { SuccessRate } from "../../../components/charts/SuccessRate";

export const RankingList: FC<any> = ({ timeSeries, users, segment }) => {
  const [sortedTimeSeries, setSortedTimeSeries] = useState<any[]>([]);

  useEffect(() => {
    if (timeSeries) {
      const _sortedTimeSeries = Object.entries(timeSeries).sort(
        (a: any, b: any) => b[1][segment] - a[1][segment]
      );
      setSortedTimeSeries(_sortedTimeSeries);
    }
  }, []);

  useEffect(() => {
    if (timeSeries) {
      const _sortedTimeSeries = Object.entries(timeSeries).sort(
        (a: any, b: any) => b[1][segment] - a[1][segment]
      );
      setSortedTimeSeries(_sortedTimeSeries);
    }
  }, [timeSeries, segment]);

  return (
    <>
      <div className="the-list">
        {sortedTimeSeries?.map((dataPoint: any, index: number) => (
          <IonItem key={dataPoint[0]}>
            <div className="start-slot" slot="start">
              <p>{index + 1}</p>
            </div>
            <IonLabel>
              <p>
                {users[dataPoint[0]].firstname +
                  " " +
                  users[dataPoint[0]].lastname}
              </p>
            </IonLabel>
            <div className="end-slot" slot="end">
              <IonText>
                {segment === "totalPoints" && dataPoint[1].totalPoints}
                {segment === "averagePoints" && dataPoint[1].averagePoints}
                {segment === "successRate" && (
                  <SuccessRate
                    fontSize={10}
                    height={50}
                    width={50}
                    rate={dataPoint[1].successRate}
                  />
                )}
              </IonText>
            </div>
          </IonItem>
        ))}
      </div>
    </>
  );
};
