import { Redirect, Route } from "react-router";
import {
  IonApp,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonPage,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { add } from "ionicons/icons";

import { AuthProvider } from "./providers/auth";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import React, { useContext, useState } from "react";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import { Tabs } from "./components/Tabs";
import UIContext from "./providers/UIProvider";
import { NewConversationModal } from "./pages/Modals/NewConversationModal";
import { Icon } from "@iconify/react";
import { Ranking } from "./pages/Ranking";
import { AkquisitionCall } from "./pages/AquisitionCall";
import Intro from "./pages/Intro/Intro";
import { Verification } from "./pages/Verification";
import { VerifyToken } from "./pages/VerifyToken";
import { PWAExplanation } from "./pages/PWAExplanation";

import { isPlatform } from "@ionic/react";
import { PasswordReset } from "./pages/PasswordReset";
import { PasswordResetApplication } from "./pages/PasswordResetApplication";

setupIonicReact();

const App: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { showFab, roleMode } = useContext(UIContext);
  let fabButtonStyle = showFab ? undefined : { display: "none" };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <IonApp>
      <IonReactRouter>
        <IonPage>
          <IonContent>
            <IonModal
              isOpen={modalOpen}
              class="modaaal"
              className="modaaal"
              onDidDismiss={handleModalClose}
            >
              <NewConversationModal onClose={() => setModalOpen(false)} />
            </IonModal>
          </IonContent>
        </IonPage>

        <IonRouterOutlet>
          <Route
            path="/app"
            render={() => (
              <>
                <Tabs />
              </>
            )}
          />
          <Route exact path="/">
            {isPlatform("pwa") ? (
              <Redirect to={"/login"} />
            ) : (
              <Redirect to={"/app-install"} />
            )}
          </Route>
          <Route exact path="/verification/:token">
            <AuthProvider pageProps={{ protected: false, loggedOut: false }}>
              <VerifyToken />
            </AuthProvider>
          </Route>
          <Route exact path="/verify">
            <AuthProvider pageProps={{ protected: true }}>
              <Verification />
            </AuthProvider>
          </Route>
          <Route exact path="/intro">
            <AuthProvider pageProps={{ protected: true }}>
              <Intro />
            </AuthProvider>
          </Route>
          <Route exact path="/app-install">
            <AuthProvider pageProps={{ protected: false, loggedOut: true }}>
              <PWAExplanation />
            </AuthProvider>
          </Route>
          <Route exact path="/login">
            <AuthProvider pageProps={{ protected: false, loggedOut: true }}>
              <Login />
            </AuthProvider>
          </Route>
          <Route exact path="/signup">
            <AuthProvider pageProps={{ protected: false, loggedOut: true }}>
              <Signup />
            </AuthProvider>
          </Route>
          <Route exact path="/password-reset/:passwordResetToken">
            <AuthProvider pageProps={{ protected: false, loggedOut: true }}>
              <PasswordReset />
            </AuthProvider>
          </Route>
          <Route exact path="/password-reset-application">
            <AuthProvider pageProps={{ protected: false, loggedOut: true }}>
              <PasswordResetApplication />
            </AuthProvider>
          </Route>
          <Route exact path="/akquisition-call/:dentistConversationId?">
            <AuthProvider pageProps={{ protected: true }}>
              <AkquisitionCall />
            </AuthProvider>
          </Route>
        </IonRouterOutlet>
        <IonFab
          onClick={() => setModalOpen(true)}
          style={fabButtonStyle}
          vertical="bottom"
          horizontal="center"
          slot={"fixed"}
        >
          <IonFabButton>
            <Icon icon="material-symbols:add" width={"24px"} height={"24px"} />
          </IonFabButton>
        </IonFab>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
