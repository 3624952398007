import {
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
} from "@ionic/react";
import { Header } from "../../components/Header";
import React, { useContext, useEffect, useState } from "react";
import "./Ranking.css";
import UIContext from "../../providers/UIProvider";
import { useRanking } from "./hooks/useRanking";
import { RankingList } from "./componets/RankingList";

export const Ranking = () => {
  const { setShowFab } = useContext(UIContext);
  const [currentMetricsData, setCurrentMetricsData] = useState<any>(null);
  const {
    metricsTotal,
    metricsToday,
    metricsWeek,
    metricsMonth,
    users,
    loading,
    data,
  } = useRanking();
  const [activeSegment, setActiveSegment] = useState<string>("totalPoints");
  const [activeTimeRange, setActiveTimeRange] = useState<string>("today");

  useEffect(() => {
    setShowFab(true);
  }, []);

  useEffect(() => {
    loadCurrentMetricsData();
  }, [metricsTotal]);

  const loadCurrentMetricsData = () => {
    switch (activeTimeRange) {
      case "today":
        setCurrentMetricsData(metricsToday);
        break;
      case "week":
        setCurrentMetricsData(metricsWeek);
        break;
      case "month":
        setCurrentMetricsData(metricsMonth);
        break;
      case "all":
        setCurrentMetricsData(metricsTotal);
        break;
    }
  };

  useEffect(() => {
    loadCurrentMetricsData();
  }, [activeTimeRange]);

  const segmentChanged = (e: any) => {
    setActiveSegment(e.detail.value);
  };

  const timeRangeChange = (e: any) => {
    setActiveTimeRange(e.detail.value);
  };

  return (
    <IonPage>
      <Header noPadding title={"Rangliste"}>
        <IonGrid>
          <IonRow>
            <IonCol size={"12"}>
              <IonSegment
                onIonChange={(e) => timeRangeChange(e)}
                value={activeTimeRange}
              >
                <IonSegmentButton value={"today"}>
                  <IonLabel>Heute</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={"week"}>
                  <IonLabel>Woche</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={"month"}>
                  <IonLabel>Monat</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={"all"}>
                  <IonLabel>Gesamt</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol size={"12"}>
              <IonSegment
                onIonChange={(e) => segmentChanged(e)}
                value={activeSegment}
              >
                <IonSegmentButton value={"totalPoints"}>
                  <IonLabel>Punktzahl</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={"averagePoints"}>
                  <IonLabel>Ø Punktzahl</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={"successRate"}>
                  <IonLabel>Erfolgsrate</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
        </IonGrid>
      </Header>
      <IonContent className="ion-padding-horizontal" fullscreen>
        {loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <IonSpinner name={"circular"}></IonSpinner>
            <p className={"ion-text-center"}>Ranglistendaten werden geladen</p>
          </div>
        )}
        {!loading && (
          <div className="details">
            <div className="list-holder ion-padding-horizontal animate__animated animate__fadeInUp">
              <RankingList
                timeSeries={currentMetricsData}
                users={users}
                segment={activeSegment}
              />
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
