import {
  IonBadge,
  IonButton,
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSegment,
  IonSegmentButton,
  useIonRouter,
} from "@ionic/react";
import { Header } from "../../components/Header";
import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../providers/UIProvider";
import { callOutline } from "ionicons/icons";
import "./Conversations.css";
import { useOpenDentistConversations } from "./hooks/useOpenDentistConversations";
import { useRetrieveConversations } from "./hooks/useRetrieveConversations";
import moment from "moment";
import "moment/locale/de";
import { NewDentistConversationModal } from "./NewDentistConversationModal";
import { Icon } from "@iconify/react";
import { EmptyContent } from "../../components/EmptyContent";
import { NewConversationModal } from "../Modals/NewConversationModal";

moment.locale("de");

export const Conversations = () => {
  const { setShowFab } = useContext(UIContext);
  const { loading, error, data, conversations } = useOpenDentistConversations();
  const { conversationsHistory } = useRetrieveConversations();

  const router = useIonRouter();

  useEffect(() => {
    setShowFab(true);
  }, []);

  const [activeSegment, setActiveSegment] =
    useState<string>("upcoming-dentist");
  const [modalOpen, setModalOpen] = useState(false);
  const [newConversationModalOpen, setNewConversationModalOpen] =
    useState(false);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);

  const segmentChanged = (e: any) => {
    setActiveSegment(e.detail.value);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <IonPage>
      <Header title={"Akquisegespräche"}>
        <IonSegment
          onIonChange={(e) => segmentChanged(e)}
          value={activeSegment as any}
        >
          <IonSegmentButton
            style={{ padding: "var(--ion-padding-1)" }}
            value={"upcoming-dentist"}
          >
            <IonLabel>
              Zahnarzt/ärztin <br /> Gespräche
            </IonLabel>
            {conversations?.length > 0 && (
              <IonBadge color="primary">{conversations.length}</IonBadge>
            )}
          </IonSegmentButton>
          -
          <IonSegmentButton value={"recent-calls"}>
            <IonLabel>
              Vergangene
              <br /> Gespräche
            </IonLabel>
            {conversationsHistory?.length > 0 && (
              <IonBadge color="primary">{conversationsHistory.length}</IonBadge>
            )}
          </IonSegmentButton>
        </IonSegment>
      </Header>
      <IonContent className="ion-padding-horizontal" fullscreen>
        <div className="details">
          <div className="segment-holder">
            <div className="list-holder animate__animated animate__fadeInUp">
              {activeSegment === "upcoming-dentist" ? (
                <div className="the-list">
                  {!conversations.length ? (
                    <EmptyContent
                      title={"Momentan stehen keine Zahnarztgespräche an"}
                      cta={true}
                      ctaText={"Gespräch beginnen"}
                      ctaDescription={
                        "Möchtest du ein neues Gespräch mit einer Zahnarzthelferin führen ?"
                      }
                      ctaCallback={() => {
                        setNewConversationModalOpen(true);
                      }}
                    />
                  ) : (
                    conversations?.map((conversationItem: any) => (
                      <IonItem key={conversationItem.id}>
                        <div className="start-slot" slot="start">
                          <img
                            src={conversationItem?.lead?.image}
                            width={"56px"}
                            alt=""
                          />
                        </div>
                        <IonLabel>
                          <h6>{conversationItem?.lead?.name}</h6>
                          <p>{moment(conversationItem?.createdAt).fromNow()}</p>
                        </IonLabel>
                        <div className="end-slot" slot="end">
                          <IonButton
                            onClick={() => {
                              setModalOpen(true);
                              setSelectedConversation(conversationItem);
                            }}
                            style={{ height: "48px", width: "48px" }}
                          >
                            <Icon
                              width={"56px"}
                              height={"56px"}
                              icon={"gis:route-end"}
                            />
                          </IonButton>
                        </div>
                      </IonItem>
                    ))
                  )}
                </div>
              ) : (
                <div></div>
              )}

              {activeSegment === "recent-calls" ? (
                !conversationsHistory.length ? (
                  <EmptyContent
                    title={"Du hast noch keine Akquisegespräche getätigt"}
                    cta={true}
                    ctaText={"Gespräch beginnen"}
                    ctaDescription={
                      "Möchtest du dein erstes Gesrpäch mit einer Zahnarzthelferin führen ?"
                    }
                    ctaCallback={() => {
                      setNewConversationModalOpen(true);
                    }}
                  />
                ) : (
                  <div className="the-list">
                    <IonList>
                      <IonItemGroup>
                        <IonItemDivider className={"ion-no-padding"} sticky>
                          <IonCard>
                            <IonItem lines="none">
                              <div className="start-slot" slot="start">
                                <h6>Person</h6>
                              </div>
                              <div slot={"end"}>
                                <h6>Punkte/maximal Punktzahl</h6>
                              </div>
                            </IonItem>
                          </IonCard>
                        </IonItemDivider>

                        {conversationsHistory.map((conversationItem) => (
                          <IonItem key={conversationItem.id}>
                            <div className="start-slot" slot="start">
                              <img src={conversationItem?.lead?.image} alt="" />
                            </div>
                            <IonLabel>
                              <h6>{conversationItem?.lead?.name}</h6>
                              <p>
                                {moment(conversationItem?.createdAt).fromNow()}
                              </p>
                            </IonLabel>
                            <IonLabel slot={"end"}>
                              <p>
                                {conversationItem?.points?.points}/
                                {conversationItem?.points?.availablePoints}
                              </p>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonItemGroup>
                    </IonList>
                  </div>
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <IonModal
          isOpen={newConversationModalOpen}
          class="modaaal"
          className="modaaal"
          onDidDismiss={() => setNewConversationModalOpen(false)}
        >
          <NewConversationModal
            onClose={() => setNewConversationModalOpen(false)}
          />
        </IonModal>
        <IonModal
          isOpen={modalOpen}
          class="modaaal"
          className="modaaal"
          onDidDismiss={handleModalClose}
        >
          <NewDentistConversationModal
            lead={selectedConversation?.lead}
            onClose={() => setModalOpen(false)}
            conversation={selectedConversation}
          />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
