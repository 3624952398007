import { FC } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import "./DoneModal.scss";
import { useHistory } from "react-router";

interface DoneModalProperties {
  lead?: any;
  dentistConversationId?: string | null;
  onClose?: any;
  timeLeft: boolean;
  currentPoints: number;
  availablePoints: number;
  minimumPoints: number;
}
export const DoneModal: FC<DoneModalProperties> = ({
  lead,
  onClose,
  timeLeft,
  currentPoints,
  availablePoints,
  minimumPoints,
  dentistConversationId,
}) => {
  const router = useIonRouter();
  const history = useHistory();

  const enoughPoints = currentPoints >= minimumPoints;

  const quit = async () => {
    await onClose(false);
  };

  return (
    <IonPage>
      <IonContent fullscreen className="modaaal">
        <IonGrid className="main-grid">
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <div className="the-holder ion-padding">
              <IonRow className="title-row">
                <IonCol size="8" class="ion-text-left">
                  {/*<h4>Conversation</h4>*/}
                </IonCol>
                <IonCol
                  size="4"
                  class="ion-text-right"
                  onClick={async () => {
                    await quit();
                  }}
                >
                  <p>abbrechen</p>
                </IonCol>
              </IonRow>
              <IonRow className={"ion-justify-content-center ion-text-center"}>
                {enoughPoints && !timeLeft && (
                  <div>
                    <img src={lead?.image} height={"224px"} alt="" />
                    <p>
                      Toll, dass du das Gespräch mit {currentPoints} von{" "}
                      {availablePoints} möglichen Punkten erreicht hast
                    </p>
                  </div>
                )}

                {!enoughPoints && !timeLeft && (
                  <div>
                    <img src={lead?.image} height={"224px"} alt="" />
                    <p>
                      Du hast leider nur {currentPoints} von minum benötigten{" "}
                      {minimumPoints} erreicht.
                    </p>
                  </div>
                )}

                {timeLeft && (
                  <div>
                    <img
                      src={"/assets/imgs/stopwatch.svg"}
                      height={"224px"}
                      alt=""
                    />
                    <p>
                      Du hast {currentPoints} von {availablePoints} möglichen
                      Punkten erreicht, jedoch ist die Zeit abgelaufen.
                    </p>
                  </div>
                )}

                <IonButton
                  onClick={() => {
                    onClose(true);
                  }}
                >
                  {enoughPoints && !timeLeft && dentistConversationId
                    ? "Direkt mit dem Zahnarzt sprechen"
                    : "Neue Zahnarztpraxis anfahren"}
                </IonButton>

                <p>Möchtest du zurück zum Dashboard ? </p>
                <IonButton
                  onClick={() => {
                    router.push("/app/dashboard", "back", "push");
                    onClose(false);
                  }}
                >
                  Zum Dashboard
                </IonButton>
              </IonRow>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
