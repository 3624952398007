import React, { FC, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
} from "@ionic/react";
import ReactApexChart from "react-apexcharts";
import { EmptyContent } from "../../../components/EmptyContent";
import { NewConversationModal } from "../../Modals/NewConversationModal";

export const WidgetLineChart: FC<any> = ({ metricsData }) => {
  const [newConversationModalOpen, setNewConversationModalOpen] =
    useState(false);

  const conversionRadialChart = {
    series: [44, 55, 67, 83],
    options: {
      chart: {
        height: 40,
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "50%",
          },
          track: {
            margin: 1,
          },
          dataLabels: {
            show: false,
          },
        },
      },
    },
  };

  const series = [
    parseInt(
      new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 3 }).format(
        (metricsData?.minimumPoints / metricsData?.maximumAvailable) * 100
      )
    ),
    parseInt(
      new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 3 }).format(
        (metricsData?.averagePoints / metricsData?.maximumAvailable) * 100
      )
    ),
    parseInt(
      new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 3 }).format(
        (metricsData?.maximumPoints / metricsData?.maximumAvailable) * 100
      )
    ),
  ];

  return (
    <>
      {!metricsData?.totalPoints && !metricsData?.availablePoints ? (
        <EmptyContent
          title={"Für diesen Bereich stehen noch keine Daten bereit"}
          cta={true}
          ctaText={"Gespräch beginnen"}
          ctaDescription={
            "Möchtest du ein neues Gespräch mit einer Zahnarzthelferin führen ?"
          }
          ctaCallback={() => {
            setNewConversationModalOpen(true);
          }}
        />
      ) : (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <h6>Punkteverteilung</h6>
            </IonCardTitle>
            <IonCardSubtitle>
              {metricsData?.totalPoints} / {metricsData?.availablePoints}{" "}
              Möglichen Punkte erreicht
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.averagePoints} Ø Punktzahl
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.minimumPoints} Niedrigste Punktzahl
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.maximumPoints} Höchste Punktzahl
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.maximumAvailable} Höchste Erreichbare Punktzahl
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.totalConversations} Gesamtgesrpäche
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.totalAssistantConversations}{" "}
              Zahnarzthelferingespräche
            </IonCardSubtitle>
            <IonCardSubtitle>
              {metricsData?.totalDentistConversations} Zahnarztgesrpäche
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            {metricsData && (
              <ReactApexChart
                // @ts-ignore
                options={conversionRadialChart.options}
                series={series}
                type="radialBar"
                height={100}
              />
            )}
          </IonCardContent>
        </IonCard>
      )}
      <IonModal
        isOpen={newConversationModalOpen}
        className="modaaal"
        onDidDismiss={() => setNewConversationModalOpen(false)}
        onAbort={() => setNewConversationModalOpen(false)}
      >
        <NewConversationModal
          onClose={() => setNewConversationModalOpen(false)}
        />
      </IonModal>
    </>
  );
};
